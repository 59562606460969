// Polyfills
import "polyfills/polyfills";

// Main
import React from "react";
import { render } from "react-dom";

// Components
import { App } from "./App";

// Config
import { ignoreErrors } from "./config/sentry";

// CSS
import "css/animations.scss";
import "css/app.scss";
import "css/bootstrap.scss";
import "css/colors.scss";
import "css/components/avatars/avatars.scss";
import "css/components/buttons/buttons.scss";
import "css/components/inputs/checkbox-radio.scss";
import "css/components/inputs/input.scss";
import "css/components/modals/modals.scss";
import "css/components/overlay/overlays.scss";
import "css/components/progress-bar/progress-bar.scss";
import "css/components/user-list/user-list.scss";
import "css/googlemaps.scss";
import "css/grid.scss";
import "css/headers.scss";
import "css/html.scss";
import "css/navbar.scss";
import "css/overrides/braintree.scss";
import "css/overrides/wordpress.scss";
import "css/utils.scss";
import "react-intl-tel-input/dist/main.css";

// Localization
import "localization/i18n";

// Platforms
import { Analytics } from "./platforms/analytics";

// Providers
import { NavBarProvider } from "context/navbar";

// Sentry
import * as Sentry from "@sentry/react";

// Utils
import {
    DEV,
    setEntryPathinSessionStorage,
    setURLQueryParamsInSessionStorage,
} from "./utils/urlUtils";

/**
 * Initialize on boot
 */

Sentry.init({
    dsn: "https://fdcf1a3ad23b41cb917ee36d3dc6f58f@o550334.ingest.sentry.io/5673818",
    environment: DEV ? "development" : "production",
    ignoreErrors,
    integrations: [
        new Sentry.BrowserTracing(),
        new Sentry.Replay({ maskAllInputs: true, maskAllText: true }),
    ],
    replaysSessionSampleRate: DEV ? 1.0 : 0.1,
    replaysOnErrorSampleRate: DEV ? 1.0 : 0.25,
    tracesSampleRate: DEV ? 1.0 : 0.75,
});

Analytics.init();

setURLQueryParamsInSessionStorage();
setEntryPathinSessionStorage();

/**
 * End initialize on boot
 */

render(
    <NavBarProvider>
        <App />
    </NavBarProvider>,
    document.getElementById("root")
);
